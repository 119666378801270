import React, { memo } from 'react';

import { Spinner, Button as BootstrapButton } from 'reactstrap';

import util from '~/assets/util';

function Button({ children, loading = false, spinnerColor = 'white', spinnerSize = 'sm', ...props }) {
  const renderButton = () => {
    if (loading) {
      return (
        <span>
          <Spinner color={spinnerColor} size={spinnerSize} /> {util.t('LOADING')}
        </span>
      );
    }

    return <>{children}</>;
  };

  return <BootstrapButton {...props}>{renderButton()}</BootstrapButton>;
}

export default memo(Button);
