import Form from './Form';

import util from '~/assets/util';

function Settings() {
  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 pl-3">{util.t('SETTINGS')}</h1>
            </header>

            <Form />
          </div>
        </section>
      </main>
    </>
  );
}

export default Settings;
