import React, { useEffect, useState } from 'react';

import Routes from './routes';

import { Spinner } from 'reactstrap';

import tenantService from '~/services/tenant';
import userService from '~/services/user';

import 'bootstrap/dist/css/bootstrap.min.css';

import '~/assets/global.scss';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      const authToken = localStorage.getItem('authtoken');

      if (authToken) {
        const user = await userService.getUser();

        if (user) {
          userService.save({ token: authToken, user });

          const tenant = await tenantService.get();

          if (tenant) {
            tenantService.save(tenant);
          }
        }
      }

      setLoading(false);
    }

    load();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner size="sm" />
      </div>
    );
  }

  return <Routes />;
}

export default App;
