import React, { memo, useRef, useState } from 'react';

import { FormGroup, Label, Col, Row, Modal as BModal, Card, CardHeader, CardBody, Input } from 'reactstrap';

import Swal from 'sweetalert2';

import Button from '~/components/Button';

import api from '~/services/api';

import notification from '~/services/notification';

import util from '~/assets/util';

function Modal(props) {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    percent_column: '',
    bar_code_column: '',
    expires_at: '',
  });

  const fileRef = useRef(null);

  const doImport = async (ev) => {
    ev.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    const file = fileRef.current.files[0];

    const formData = new FormData();

    formData.append('document', file);
    formData.append('expires_at', form.expires_at);
    formData.append('bar_code_column', form.bar_code_column);
    formData.append('percent_column', form.percent_column);

    const url = 'admin/products/rule/import';

    try {
      const { data } = await api.post(url, formData);

      showResults(data);

      props.toggle();
    } catch (e) {
      showErrors(e);

      fileRef.current.value = null;

      console.error('ImportRulesButton.doSave', e);
    } finally {
      setLoading(false);
    }
  };

  const showResults = (results) => {
    const imported = results.imported;
    const ignored = results.ignored;

    Swal.fire({
      icon: 'success',
      html: util.t('IMPORT_PRICE_RULES_SUCCESS').replace('{total}', imported).replace('{ignored}', ignored),
    });
  };

  const showErrors = (error) => {
    const data = error?.response?.data;

    if (!data?.line) {
      return notification.$e(error);
    }

    Swal.fire({
      icon: 'error',
      html: `${util.t('IMPORT_PRICE_RULES_ERROR').replace('{line}', data.line)} <br/> ${util.t(data.message)}`,
    });
  };

  return (
    <BModal size="lg" {...props}>
      <Card>
        <CardHeader>{util.t('CREATE_DISCOUNT')}</CardHeader>
        <CardBody>
          <form onSubmit={doImport} className="container-fluid p-3">
            <Row form>
              <Col>
                <FormGroup>
                  <Label htmlFor="price-rules-file">{util.t('SPREADSHEET')}</Label>
                  <Input
                    type="file"
                    className="form-control-file"
                    placeholder={util.t('SELECT')}
                    name="price-rules-file"
                    id="price-rules-file"
                    innerRef={fileRef}
                    required
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Row form>
              <Col sm="6" md="4">
                <FormGroup>
                  <Label htmlFor="expires-at">{util.t('EXPIRATION_DATE')}</Label>
                  <Input
                    type="date"
                    value={form.expires_at}
                    id="expires-at"
                    name="expires-at"
                    required
                    onChange={(ev) => setForm({ ...form, expires_at: ev.target.value })}
                  />
                </FormGroup>
              </Col>

              <Col sm="6" md="4">
                <FormGroup>
                  <Label htmlFor="bar-code-column">{util.t('BAR_CODE_COLUMN')} (*)</Label>
                  <Input
                    type="text"
                    placeholder="A"
                    className="text-uppercase"
                    name="bar-code-column"
                    id="bar-code-column"
                    required
                    onChange={(ev) => setForm({ ...form, bar_code_column: ev.target.value })}
                  />
                </FormGroup>
              </Col>

              <Col sm="6" md="4">
                <FormGroup>
                  <Label htmlFor="percent-column">{util.t('PERCENT_COLUMN')} (*)</Label>
                  <Input
                    type="text"
                    placeholder="B"
                    className="text-uppercase"
                    name="percent-column"
                    id="percent-column"
                    required
                    onChange={(ev) => setForm({ ...form, percent_column: ev.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <footer className="d-flex">
                  <Button loading={loading} type="submit" color="primary">
                    {util.t('IMPORT')}
                  </Button>
                </footer>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
