import util from '~/assets/util';
import { AttributesLabels } from '~/assets/constants';

const service = {
  parseAttributes(attributes) {
    if (!attributes || !attributes.length) return [];

    return attributes.map((attribute) => {
      if (attribute.tenant_id != null) return attribute;

      const name = util.t(AttributesLabels[attribute.name]);

      return { ...attribute, name };
    });
  },
};

export default service;
