import axios from 'axios';

import https from 'https';

let urlApi = process.env.REACT_APP_API_URL;

const config = {
  baseURL: urlApi,
};

if (process.env.NODE_ENV == 'development') {
  config.httpsAgent = new https.Agent({ rejectUnauthorized: false });
}

const http = axios.create(config);

http.CancelToken = axios.CancelToken;

http.interceptors.request.use(
  function (config) {
    const authToken = localStorage.getItem('authtoken');

    if (authToken) {
      config.headers.common.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default http;
