import api from './api';
import store from '~/store';

import { Types } from '~/store/constants';

import notification from './notification';

import util from '~/assets/util';
import { Roles } from '~/assets/constants';

const service = {
  async login(email, password) {
    try {
      const res = await api.post('admin/login', {
        email,
        password,
        restore_cart: false,
      });

      const data = res.data;

      return this.save(data);
    } catch (e) {
      if (e.response.status === 401) {
        notification.$e(util.t('WRONG_CREDENTIALS'));
      } else {
        notification.$e(e);
      }

      return false;
    }
  },

  save(data) {
    if (data.token && data.user) {
      const user = data.user;
      const token = data.token;

      if (user.role != Roles.ADMIN) {
        notification.$e(util.t('UNAUTHORIZED'));

        return false;
      }

      store.dispatch({ type: Types.USER_TOKEN_SET, token });
      store.dispatch({ type: Types.USER_SET, data: user });

      localStorage.setItem('authtoken', token);

      return data;
    }

    return false;
  },

  async getUser() {
    try {
      const res = await api.get('/auth/me');

      const data = res.data;

      return data.user;
    } catch (e) {
      console.error('user.getUser error', e);
    }
  },

  logout() {
    store.dispatch({ type: Types.USER_RESET });

    return localStorage.removeItem('authtoken');
  },
};

export default service;
