import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { format, subMonths } from 'date-fns';

import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

import { Link } from 'react-router-dom';

import { FaMoneyBill, FaShoppingBag, FaFileExcel, FaTicketAlt } from 'react-icons/fa';

import Filters from './Filters';

import Button from '~/components/Button';
import InfoCard from '~/components/InfoCard';
import Pagination from '~/components/Pagination';
import OrdersTable from '~/components/OrdersTable';
import OrderDetails from '~/components/OrderDetails';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';
import { TenantsSlug } from '~/assets/constants';

const today = new Date();
const DEFAULT_FILTERS = {
  date_start: format(subMonths(today, 1), 'y-MM-dd'),
  date_end: format(today, 'y-MM-dd'),
  store_order_id: '',
  status: '',
  coupon: '',
  customer_name: '',
  customer_email: '',
  order_id: '',
  store_id: '',
};

function Orders({}) {
  const tenant = useSelector((state: any) => state.tenant);

  const [orderId, setOrderId]: any = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const [reports, setReports]: any = useState({});

  const [orders, setOrders]: any = useState(null);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingReport, setLoadingReport] = useState(false);

  const loadOrders = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    setLoadingOrders(true);

    try {
      const res = await api.get(`admin/orders?page=${page}`, {
        params: filters,
      });

      let { orders } = res.data;

      orders.links?.shift();
      orders.links?.splice(orders.links.length - 1, 1);

      setOrders(orders);
    } catch (e) {
      notification.$e(e);
      console.error(e);
    } finally {
      setLoadingOrders(false);
    }
  };

  const loadReports = async () => {
    try {
      const res = await api.get(`admin/orders/reports`, {
        params: filters,
      });

      let { reports } = res.data;

      setReports(reports);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    }
  };

  const doSearch = async () => {
    loadOrders();
    loadReports();
  };

  const createReport = async (filters: any) => {
    try {
      setLoadingReport(true);

      const res = await api.post('admin/orders/sales-report', {
        date_start: filters?.date_start,
        date_end: filters?.date_end,
        status: filters?.status,
      });

      const { path } = res.data;

      location.href = path;

      if (tenant.slug == TenantsSlug.FEIRA_LER) {
        notification.$s('Relatório gerado com sucesso! Em alguns minutos estará disponível na aba Ver Relatórios.');
      }
    } catch (e) {
      if (tenant.slug != TenantsSlug.FEIRA_LER) {
        notification.$e(e);
      }

      console.error(e);
    } finally {
      setLoadingReport(false);
    }
  };

  const onChangeOrderStatus = (storeOrder) => {
    const id = storeOrder.store_order_id;

    const status = storeOrder.status;

    const data = [...orders.data];

    const index = data.findIndex((storeOrder) => id == storeOrder.store_order_id);

    if (index == -1) {
      return false;
    }

    data[index].status = status;

    setOrders({ ...orders, data });
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="my-3">
        <section className="row">
          <div className="col-lg-6 col-xl-4 col-md-6 mb-3">
            <InfoCard title="ORDERS_RECEIVED" Icon={<FaShoppingBag size="50" />} value={reports.orders_received || '-'} />
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6 mb-3">
            <InfoCard title="TOTAL_SALES" Icon={<FaMoneyBill size="50" />}>
              <h2 className="m-0 mr-auto">{reports.total_sales ? `R$ ${util.format(reports.total_sales)}` : '-'}</h2>
            </InfoCard>
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6 mb-3">
            <InfoCard title="AVERAGE_TICKET" Icon={<FaTicketAlt size="50" />}>
              <h2 className="m-0 mr-auto">{reports.average_ticket ? `R$ ${util.format(reports.average_ticket)}` : '-'}</h2>
            </InfoCard>
          </div>
        </section>

        <section className="row">
          <div className="col-12">
            <div className="card p-3">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={doSearch}
                onClear={() => {
                  setFilters(DEFAULT_FILTERS);
                }}
              />
            </div>
          </div>
        </section>
      </header>

      <main className="card">
        <div className="container-fluid p-3">
          <header className="mb-3">
            <h1 className="h2 m-0">{util.t('ORDERS')}</h1>
          </header>

          <div className="text-right">
            <Button
              disabled={!orders?.data?.length}
              loading={loadingReport}
              onClick={() => createReport(filters)}
              className="mb-3 align-items-center"
              color="primary"
              outline>
              <FaFileExcel className="mr-1" />
              {util.t('SALES_REPORT')}
            </Button>

            {tenant.slug == TenantsSlug.FEIRA_LER && (
              <Link to="/reports">
                <Button className="ml-2 mb-3 align-items-center" outline color="info">
                  <FaFileExcel className="mr-1" />
                  {util.t('SEE_REPORTS')}
                </Button>
              </Link>
            )}
          </div>

          <section>
            <OrdersTable
              loading={loadingOrders}
              orders={orders}
              onOpen={(id) => {
                setOrderId(id);
              }}
              setOrders={setOrders}
              onChangeOrderStatus={onChangeOrderStatus}
            />
          </section>
          <footer className="d-flex justify-content-end mt-4">
            {orders && <Pagination onChange={(page) => loadOrders(page)} {...orders} />}
          </footer>
        </div>
      </main>

      <Modal autoFocus={false} isOpen={orderId != null} size="xl" toggle={() => setOrderId(null)} unmountOnClose>
        <Card>
          <CardHeader>
            {util.t('ORDER')} <strong>{orderId}</strong>
          </CardHeader>
          <CardBody>
            <OrderDetails onChange={onChangeOrderStatus} id={orderId} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

export default Orders;
