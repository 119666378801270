import React, { memo, useState } from 'react';

import { FaImage } from 'react-icons/fa';
import { UncontrolledTooltip } from 'reactstrap';

import Button from '../Button';

import Modal from './Modal';

import util from '~/assets/util';

function AppendProductImageButton({ productId, currentUrl, onChange, ...props }) {
  const id = `btn-append-image-${productId}`;

  const [showModal, setShowModal] = useState(false);

  const onSave = (productId, thumbnailUrl) => {
    setShowModal(false);

    window.setTimeout(() => onChange(productId, thumbnailUrl), 500);
  };

  return (
    <>
      <Button id={id} outline color="primary" type="button" size="sm" onClick={() => setShowModal(true)} {...props}>
        <FaImage />
      </Button>
      <UncontrolledTooltip target={id}>{util.t('ADD_COVER_IMAGE')}</UncontrolledTooltip>

      <Modal currentUrl={currentUrl} productId={productId} isOpen={showModal} onClose={() => setShowModal(false)} onSave={onSave} />
    </>
  );
}

export default memo(AppendProductImageButton);
