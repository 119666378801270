import React, { memo } from 'react';

import StatusHistoryButton from '~/components/StatusHistoryButton';
import ChangeOrderStatusButton from '~/components/ChangeOrderStatusButton';

import { Badge } from 'reactstrap';

import orderService from '~/services/order';

function Status({ onSave, storeOrder }) {
  const status = orderService.getStatus(storeOrder.status);

  return (
    <>
      <Badge color={status.color}>{status.label}</Badge>

      <div>
        <StatusHistoryButton logs={storeOrder.status_logs} />
        <ChangeOrderStatusButton className="ml-1" onSave={onSave} storeOrder={storeOrder} />
      </div>
    </>
  );
}

export default memo(Status);
