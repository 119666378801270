import React, { memo, useEffect, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';

import FormButtons from '~/components/FormButtons';
import ImageInput from '~/components/ImageInput';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

function Form({ id, onClose, onSave }) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [type, setType] = useState<any>(1);

  const [imageSection, setImageSection]: any = useState({
    title: '',
    position: 1,
    first_link: '',
    second_link: '',
    active: true,
  });

  const inputTitleRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setImageSection({ ...imageSection, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      let formData = new FormData();

      if (imageSection.first_file) {
        formData.append('first_image', imageSection.first_file);
      }

      if (imageSection.second_file) {
        formData.append('second_image', imageSection.second_file);
      }

      formData.append('title', imageSection.title);
      formData.append('active', imageSection.active ? '1' : '0');
      formData.append('position', imageSection.position);
      formData.append('first_link', imageSection.first_link ?? '');
      formData.append('second_link', imageSection.second_link ?? '');
      formData.append('image_section_id', imageSection.image_section_id);

      const url = id ? `admin/image-section/${id}` : 'admin/image-section';

      const res = await api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('SECTION_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadImageSection() {
      setLoading(true);

      try {
        const res = await api.get(`admin/image-section/${id}`);

        const data = res?.data;

        if (data.image_section) {
          setImageSection(data.image_section);

          if (data?.image_section?.first_url?.length > 0 && data?.image_section?.second_url?.length > 0) {
            setType(2);
          } else {
            setType(1);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadImageSection();
    }
  }, []);

  const handleOnChangeImage = (first_image, first_file) => {
    if (editing) {
      setEditing(false);
    }

    setImageSection({ ...imageSection, first_image, first_file });
  };

  const handleOnChangeSecondImage = (second_image, second_file) => {
    if (editing) {
      setEditing(false);
    }

    setImageSection({ ...imageSection, second_image, second_file });
  };

  useEffect(() => {
    !editing && inputTitleRef?.current?.focus();
  }, [editing]);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset disabled={editing}>
              <Row form>
                <Col md="6" lg="6" xl="4">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('TITLE')}</Label>
                    <Input
                      className="form-control"
                      value={imageSection.title}
                      onChange={handleChange}
                      type="text"
                      name="title"
                      id="title"
                      maxLength={255}
                      required
                      autoFocus
                      innerRef={inputTitleRef}
                    />
                  </FormGroup>
                </Col>
                <Col sm="4" lg="2" xl="1">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('POSITION')}</Label>
                    <select 
                      value={imageSection.position} 
                      onChange={handleChange} 
                      className="form-control" 
                      id="position" 
                      name="position" 
                      required>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                  </FormGroup>
                </Col>
                {!id &&
                  <Col sm="4" lg="2" xl="2">
                    <FormGroup>
                      <Label htmlFor="name">{util.t('TYPE')}</Label>
                      <select 
                        value={type}
                        onChange={(ev) => setType(ev.target.value)} 
                        className="form-control" 
                        id="type" 
                        name="type" 
                        required>
                          <option value="1">1 Imagem</option>
                          <option value="2">2 Imagens</option>
                      </select>
                    </FormGroup>
                  </Col>
                }
                <Col sm="3" lg="1">
                  <FormGroup>
                    <Label htmlFor="active">{util.t('STATUS')}</Label>
                    <StatusSwitch
                      disabled={editing}
                      onChange={(value) => setImageSection({ ...imageSection, active: value })}
                      checked={!!imageSection.active}
                      name="active"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </fieldset>
            <hr />
            <Row form>
              <Col md={(type == 1) ? 12 : 6}>
                <ImageInput
                  className="mb-3"
                  src={imageSection.first_image}
                  id="image"
                  name="image"
                  onChange={handleOnChangeImage}
                  alt={imageSection.title}
                  disabled={editing}
                />
                <FormGroup>
                    <Label htmlFor="first_link">{util.t('LINK')}</Label>
                    <Input
                      className="form-control"
                      value={imageSection.first_link || ''}
                      onChange={handleChange}
                      maxLength={255}
                      type="url"
                      name="first_link"
                      id="first_link"
                    />
                </FormGroup>
              </Col>
              {type == 2 ?
                <Col md="6" lg="6" xl="6">
                  <ImageInput
                    className="mb-3"
                    src={imageSection.second_image}
                    id="second_image"
                    name="second_image"
                    onChange={handleOnChangeSecondImage}
                    alt={imageSection.title}
                    disabled={editing}
                  />
                  <FormGroup>
                      <Label htmlFor="second_link">{util.t('LINK')}</Label>
                      <Input
                        className="form-control"
                        value={imageSection.second_link || ''}
                        onChange={handleChange}
                        maxLength={255}
                        type="url"
                        name="second_link"
                        id="second_link"
                      />
                  </FormGroup>
                </Col>
              : null}
            </Row>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
