import React, { memo, useCallback, useState } from 'react';

import { FaSearchPlus } from 'react-icons/fa';
import { Button, Spinner, Table } from 'reactstrap';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

function ImageSectionTable({ sections, loading = false, onOpen, doUpdate }) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = async (id) => {
    try {
      setDeleting(deleting.concat([id]));

      const url = `admin/image-section/${id}`;

      await api.delete(url);

      doUpdate();
    } catch (e) {
      notification.$e(e);
    } finally {
      setDeleting(deleting.filter((deletingId) => deletingId != id));
    }
  };

  const list = useCallback(
    sections?.data?.map((section) => (
      <tr key={section.image_section_id}>
        <td>{section.title}</td>
        <td>{section.position}</td>
        <td>{util.t(section.active ? 'ACTIVE' : 'INACTIVE')}</td>
        <td>
          <div className="d-flex align-center justify-content-end">
            <Button className="mr-1" onClick={() => onOpen(section.image_section_id)} size="sm" outline color="info">
              <FaSearchPlus />
            </Button>

            <DeleteButton onDelete={() => doDelete(section.image_section_id)} size="sm" loading={deleting.indexOf(section.image_section_id) > -1} />
          </div>
        </td>
      </tr>
    )),
    [sections, deleting],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (sections?.data?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('TITLE')}</th>
            <th>{util.t('POSITION')}</th>
            <th>{util.t('STATUS')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(ImageSectionTable);
