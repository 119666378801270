import React, { memo, useState, useEffect, useRef } from 'react';

import { Col, Modal, Card, CardHeader, CardBody, Form, Row, Label, FormGroup, Input, Spinner } from 'reactstrap';

import FormButtons from '~/components/FormButtons';
import SearchCategoryInput from '~/components/Category/SearchCategoryInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function CategoryModal({ isOpen = false, id, onSave, onToggle, defaultName = '' }) {
  const DEFAULT_STATE = {
    name: defaultName,
    root_category_id: 0,
  };

  const [loading, setLoading] = useState(!!id);
  const [editing, setEditing] = useState(!!id);

  const [rootCategory, setRootCategory] = useState({
    category_id: null,
    name: '',
  });

  const [category, setCategory] = useState(DEFAULT_STATE);

  const inputNameRef = useRef(null);
  const rootCategoryRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setCategory({ ...category, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (loading) {
      return;
    }

    if (rootCategory.category_id === 0) {
      notification.$e(util.t('SELECT_A_ROOT_CATEGORY'));

      return;
    }

    setLoading(true);

    const url = 'admin/categories';

    let form: any = {
      name: category.name,
    };

    if (rootCategory.category_id > 0) {
      form.root_category_id = rootCategory.category_id;
    }

    try {
      const { data } = id ? await api.put(`${url}/${id}`, category) : await api.post(url, form);

      notification.$s(util.t('CATEGORY_SAVED'));

      setCategory(DEFAULT_STATE);

      onSave(data?.category);
    } catch (e) {
      console.error('categoryModal.doSave', e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  async function loadCategory(id) {
    try {
      const { data } = await api.get(`admin/categories/${id}`);

      const category = data?.category;

      setCategory(category);
    } catch (e) {
      notification.$e(e);
    } finally {
      setLoading(false);
    }
  }

  const changeRootCategory = (option) => {
    option && setRootCategory(option);
  };

  useEffect(() => {
    if (!editing) {
      inputNameRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    id && loadCategory(id);
  }, []);

  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={onToggle} size="xl">
      <Card>
        <CardHeader>{util.t('CATEGORY')}</CardHeader>
        <CardBody>
          {loading ? (
            <Spinner color="primary" className="d-block m-auto" />
          ) : (
            <Form className="container-fluid" onSubmit={doSave}>
              <fieldset disabled={editing}>
                <Row form>
                  <Col lg="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        {util.t('NAME')} <strong>*</strong>
                      </Label>

                      <Input
                        autoFocus
                        id="name"
                        name="name"
                        value={category.name}
                        onChange={handleChange}
                        innerRef={inputNameRef}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          name="personCheckbox"
                          type="radio"
                          checked={rootCategory.category_id == null}
                          value={1}
                          onChange={() => setRootCategory({ ...rootCategory, category_id: null })}
                        />
                        {util.t('ROOT_CATEGORY')}
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          name="personCheckbox"
                          type="radio"
                          value={0}
                          checked={rootCategory.category_id != null}
                          onChange={() => setRootCategory({ ...rootCategory, category_id: 0 })}
                        />
                        {util.t('SUBCATEGORY')}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {rootCategory.category_id != null && (
                  <Row form>
                    <Col lg="6">
                      <FormGroup>
                        <Label htmlFor="root_category_id">{util.t('ROOT_CATEGORY')}</Label>

                        <SearchCategoryInput
                          id="root_category_id"
                          value={rootCategory}
                          setRef={(ref) => {
                            rootCategoryRef.current = ref;
                          }}
                          onChange={changeRootCategory}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </fieldset>
              <FormButtons isEditing={editing} isSaving={loading} onCancel={onToggle} onEdit={() => setEditing(false)} />
            </Form>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(CategoryModal);
