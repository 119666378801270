import React, { memo, useEffect, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';
import Select from 'react-select';

import FormButtons from '~/components/FormButtons';
import ImageInput from '~/components/ImageInput';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

function Form({ id, onClose, onSave }) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [banner, setBanner]: any = useState({
    active: true,
    type: 'desktop'
  });

  const inputTitleRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setBanner({ ...banner, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      let formData = new FormData();

      if (banner.file) {
        formData.append('image', banner.file);
      }

      formData.append('url', banner.url);
      formData.append('link', banner.link);
      formData.append('title', banner.title);
      formData.append('active', banner.active ? '1' : '0');
      formData.append('position', banner.position);
      formData.append('banner_id', banner.banner_id);
      formData.append('transition_time', banner.transition_time);
      formData.append('type', banner.type);

      const url = id ? `admin/carousel/${id}` : 'admin/carousel';

      const res = await api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

      const data = res?.data;

      if (data.banner) {
        onSave();
        onClose();

        notification.$s(util.t('BANNER_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadBanner() {
      setLoading(true);

      try {
        const res = await api.get(`admin/carousel/${id}`);

        const data = res?.data;

        if (data.banner) {
          setBanner(data.banner);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadBanner();
    }
  }, []);

  const handleOnChangeImage = (url, file) => {
    if (editing) {
      setEditing(false);
    }

    setBanner({ ...banner, url, file });
  };

  useEffect(() => {
    !editing && inputTitleRef?.current?.focus();
  }, [editing]);

  const types = [
    { name: 'type', value: 'desktop', label: util.t('DESKTOP') },
    { name: 'type', value: 'mobile', label: util.t('MOBILE') },
  ];

  const handleChangeSelect = (selectedOption) => {
    if (!selectedOption) {
      return;
    }
  
    setBanner((prevBanner) => ({
      ...prevBanner,
      type: selectedOption.value,
    }));
  };

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <Row form>
              <Col>
                <ImageInput
                  src={banner.url}
                  id="banner"
                  name="banner"
                  onChange={handleOnChangeImage}
                  alt={banner.title}
                  disabled={editing}
                />
              </Col>
            </Row>
            <fieldset disabled={editing}>
              <Row form>
                <Col md="12" className="container-format mb-2">
                  <div className="format">
                    {util.t('WEBP')}
                  </div>
                  <div className="format">
                    {util.t('JPEG')}
                  </div>
                  <div className="format">
                    {util.t('PNG')}
                  </div>
                  <div className="vertical-divider"></div>
                  <div className="format">
                    {banner.type == 'desktop' ? util.t('RES_DESKTOP') : util.t('RES_MOBILE')}
                  </div>
                </Col>
                <Col md="6" lg="4" xl="3">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('TITLE')}</Label>
                    <Input
                      className="form-control"
                      value={banner.title}
                      onChange={handleChange}
                      type="text"
                      name="title"
                      id="title"
                      maxLength={255}
                      required
                      autoFocus
                      innerRef={inputTitleRef}
                    />
                  </FormGroup>
                </Col>
                <Col md="5" lg="5" xl="3">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('LINK')}</Label>
                    <Input
                      className="form-control"
                      value={banner.link}
                      onChange={handleChange}
                      maxLength={255}
                      type="url"
                      name="link"
                      id="link"
                    />
                  </FormGroup>
                </Col>
                <Col sm="4" lg="2" xl="1">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('POSITION')}</Label>
                    <Input
                      className="form-control"
                      value={banner.position}
                      onChange={handleChange}
                      type="number"
                      name="position"
                      id="position"
                      min={1}
                      max={255}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm="5" lg="3" xl="2">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('TRANSITION_TIME')}</Label>
                    <Input
                      className="form-control"
                      value={banner.transition_time}
                      onChange={handleChange}
                      type="number"
                      min={1}
                      max={127}
                      name="transition_time"
                      id="transition_time"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm="5" lg="3" xl="2">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('TYPE')}</Label>
                    <Select
                      name="type"
                      placeholder={util.t('SELECT')}
                      value={types.find(option => option.value === banner.type)}
                      onChange={handleChangeSelect} 
                      isSearchable={false}
                      options={types}
                      isDisabled={editing}
                    />
                  </FormGroup>
                </Col>
                <Col sm="3" lg="1">
                  <FormGroup>
                    <Label htmlFor="active">{util.t('STATUS')}</Label>
                    <StatusSwitch
                      disabled={editing}
                      onChange={(value) => setBanner({ ...banner, active: value })}
                      checked={!!banner.active}
                      name="active"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </fieldset>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
