import React, { memo, useState } from 'react';

import debounce from 'lodash.debounce';

import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

import AsyncCreatableSelect from 'react-select/async-creatable';

import { FaPlus } from 'react-icons/fa';

import BrandModal from '../BrandModal';

import api from '~/services/api';

import util from '~/assets/util';

function SearchBrandInput({ onChange, canCreate = true, ...props }) {
  const [showModal, setShowModal] = useState(false);

  async function loadDataOptions(inputValue) {
    try {
      const res = await api.get(`admin/brands/search?q=${inputValue}`);

      const { brands } = res?.data;

      if (!brands) {
        return [];
      }

      return brands?.map((item) => {
        return new Object({ name: 'brand_id', value: item.brand_id, label: item.name });
      });
    } catch (e) {
      console.error(e);
    }
  }

  const loadBrands = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions(inputValue));
    });

  const debouncedLoadBrands = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadBrands(inputValue).then((options) => callback(options));
  }, 1000);

  const handleNewBrand = (brand) => {
    props.onCreate && props.onCreate(brand);

    setShowModal(false);
  };

  return (
    <>
      <InputGroup>
        <AsyncCreatableSelect
          {...props}
          placeholder={util.t('SEARCH_OR_CREATE_BRAND')}
          className="react-select-full-width"
          loadOptions={debouncedLoadBrands}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          noOptionsMessage={() => util.t('NO_OPTION')}
          formatCreateLabel={(brandName) => `${util.t('LABEL_CREATE')} "${brandName}"`}
          isClearable
          cacheOptions
          onChange={onChange}
        />

        {canCreate && (
          <InputGroupAddon addonType="append">
            <Button
              id="new-brand-button"
              alt={util.t('NEW_BRAND')}
              type="button"
              onClick={() => setShowModal(true)}
              size="sm"
              color="primary">
              <FaPlus />
            </Button>

            <UncontrolledTooltip target="new-brand-button">{util.t('NEW_BRAND')}</UncontrolledTooltip>
          </InputGroupAddon>
        )}
      </InputGroup>

      <BrandModal id={0} isOpen={showModal} onSave={handleNewBrand} onToggle={() => setShowModal(false)} />
    </>
  );
}

export default memo(SearchBrandInput);
