import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Pagination from '~/components/Pagination';
import OrdersTable from '~/components/OrdersTable';
import OrderDetails from '~/components/OrderDetails';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function Orders() {
  let { tenantId } = useParams();

  const [orderId, setOrderId]: any = useState(null);
  const [orders, setOrders]: any = useState(null);
  const [franchisee, setFranchisee]: any = useState({
    name: '',
  });
  const [loading, setLoading] = useState(true);

  const loadOrders = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    setLoading(true);

    try {
      const res = await api.get(`admin/franchising/orders?page=${page}&id=${tenantId}`);

      let { franchisee, orders } = res.data;

      orders.links?.shift();
      orders.links?.splice(orders.links.length - 1, 1);

      setOrders(orders);
      setFranchisee(franchisee);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const doSearch = async () => {
    loadOrders();
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card mt-3">
        <div className="container-fluid p-3">
          <header className="mb-3">
            <small>{franchisee.name}</small>
            <h1 className="h2 m-0">{util.t('ORDERS')}</h1>
          </header>
          <section>
            <OrdersTable
              loading={loading}
              orders={orders}
              onOpen={(id) => {
                setOrderId(id);
              }}
            />
          </section>
          <footer className="d-flex justify-content-end mt-4">
            {orders?.data?.length && <Pagination onChange={(page) => loadOrders(page)} {...orders} />}
          </footer>
        </div>
      </main>

      <Modal autoFocus={false} isOpen={orderId != null} size="xl" toggle={() => setOrderId(null)}>
        <Card>
          <CardHeader>
            {util.t('ORDER')} <strong>{orderId}</strong>
          </CardHeader>
          <CardBody>
            <OrderDetails id={orderId} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

export default Orders;
