import React, { memo, useState } from 'react';
import { FaShippingFast, FaTruck, FaMoneyBillWave, FaGift } from 'react-icons/fa';
import { BsCreditCard } from 'react-icons/bs';
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { AiOutlineSafety } from "react-icons/ai";

import { Col, Form as BForm, FormGroup, Input, Label, Row } from 'reactstrap';

import Button from '~/components/Button';

import api from '~/services/api';

import util from '~/assets/util';

import './index.scss';
import notification from '~/services/notification';

function Form({ icon, availableIcons, onSave, defaultPosition = 1 }) {
  const [form, setForm] = useState(
    icon || {
      description: '',
      id: 0,
      label: '',
      position: defaultPosition,
      icon: '',
    },
  );
  const [saving, setSaving] = useState(false);

  const id = form?.id;

  const checkIcon = (icon) => setForm({ ...form, icon });

  const doSave = async (ev) => {
    ev.preventDefault();

    setSaving(true);

    const icon = 'admin/home-icons';

    try {
      const { data } = await (id ? api.put(icon, form) : api.post(icon, form));

      notification.$s(util.t('SAVE_HOME_ICON_SUCCESS'));

      data && onSave(data.icon);
    } catch (e) {
      notification.$e(e);

      console.error('HomeIcons.Form.doSave', e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <BForm className="available-icons" onSubmit={doSave}>
      <Row form>
        <Col lg="5">
          <FormGroup>
            <Label htmlFor="icon-label">{util.t('TITLE')}</Label>

            <Input
              type="text"
              value={form.label}
              autoFocus
              id="icon-label"
              name="icon-label"
              placeholder={util.t('HOME_ICON_LABEL_PLACEHOLDER')}
              onChange={(ev) => setForm({ ...form, label: ev.target.value })}
              required
            />
          </FormGroup>
        </Col>

        <Col lg="5">
          <FormGroup>
            <Label htmlFor="icon-label">{util.t('DESCRIPTION')}</Label>

            <Input
              type="text"
              value={form.description}
              id="icon-description"
              name="icon-description"
              placeholder={util.t('HOME_ICON_descriPTION_PLACEHOLDER')}
              onChange={(ev) => setForm({ ...form, description: ev.target.value })}
            />
          </FormGroup>
        </Col>

        <Col sm="2" md="1">
          <FormGroup>
            <Label htmlFor="icon-position">{util.t('POSITION')}</Label>

            <Input
              min={1}
              type="number"
              value={form.position}
              id="icon-position"
              name="icon-position"
              onChange={(ev) => setForm({ ...form, position: ev.target.value })}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-1" form>
        <Label>{util.t('SELECT_AN_ICON')}</Label>
      </Row>
      <Row form>
        {availableIcons.map((icon, index) => {
          const selected = icon == form.icon;

          const id = `available-icon-${index};`;

          return (
            <Col key={index}>
              <FormGroup className={`available-icon ${selected ? 'selected' : ''}`}>
                <Input type="radio" checked={selected} id={id} name="selected-icon" onChange={() => checkIcon(icon)} required />

                <Label htmlFor={id}>
                  {icon == 'HiOutlineSwitchHorizontal' && <HiOutlineSwitchHorizontal size="80" />}
                  {icon == 'FaMoneyBillWave' && <FaMoneyBillWave size="80" />}
                  {icon == 'AiOutlineSafety' && <AiOutlineSafety size="80" />}
                  {icon == 'FaShippingFast' && <FaShippingFast size="80" />}
                  {icon == 'BsCreditCard' && <BsCreditCard size="80" />}
                  {icon == 'FaTruck' && <FaTruck size="80" />}
                  {icon == 'FaGift' && <FaGift size="80" />}
                </Label>
              </FormGroup>
            </Col>
          );
        })}
      </Row>

      <Row className="mb-2" form>
        <Col>
          <Button type="submit" color="primary" loading={saving}>
            {util.t('SAVE')}
          </Button>
        </Col>
      </Row>
    </BForm>
  );
}

export default memo(Form);
