import React, { useState, useEffect } from 'react';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Form from './Form';

import HomeIconsTable from '~/components/HomeIconsTable';

import api from '~/services/api';

import util from '~/assets/util';

function HomeIcons() {
  const [icons, setIcons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [icon, setIcon] = useState(null);
  const [available, setAvailable] = useState(null);

  const loadIcons = async () => {
    try {
      setLoading(true);

      const res = await api.get('admin/home-icons');

      let { icons } = res.data;
      let { available } = res.data;

      setIcons(icons);
      setAvailable(available);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onSaveIcon = (icon) => {
    setIcon(null);

    loadIcons();
  };

  useEffect(() => {
    loadIcons();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('ICONS')}</h1>
              <p>{util.t('ICONS_DESCRIPTION')}</p>
            </header>

            <div className="text-right">
              <Button disabled={icons?.length >= 7} onClick={() => setIcon(0)} className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <HomeIconsTable loading={loading} icons={icons} doUpdate={loadIcons} onOpen={(icon) => setIcon(icon)} />
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={icon != null}
          toggle={() => {
            setIcon(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('ICON')}</CardHeader>
            <CardBody>
              <Form icon={icon} onSave={(icon) => onSaveIcon(icon)} defaultPosition={icons.length + 1} availableIcons={available} />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default HomeIcons;
