import { BrowserRouter, Route, Switch } from 'react-router-dom';


import Banners from './pages/Banners';
import Brands from './pages/Brands';
import Catalogues from './pages/Catalogues';
import Categories from './pages/Categories';
import Coupons from './pages/Coupons';
import Dashboard from './pages/Dashboard';
import Franchisees from './pages/Franchisees';
import FranchiseesOrders from './pages/Franchisees/Orders';
import General from './pages/General';
import HomeIcons from './pages/HomeIcons';
import Integration from './pages/Integration';
import Login from '~/pages/Login';
import Orders from './pages/Orders';
import PagSeguroConnect from './pages/PagSeguroConnect';
import Product from './pages/Products/new';
import Products from './pages/Products';
import Reports from './pages/Reports';
import Sections from './pages/Sections';
import Settings from './pages/Settings';
import Store from './pages/Stores/new';
import Stores from './pages/Stores';
import Users from './pages/Users';
import StyleEditor from './pages/StyleEditor';

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route component={Login} path="/login" exact />
          <Dashboard>
            <Route component={Orders} path="/" exact />
            <Route component={Orders} path="/home" exact />
            <Route component={Orders} path="/orders" exact />
            <Route component={Reports} path="/reports" exact />
            <Route component={Users} path="/users" exact />
            <Route component={Banners} path="/banners" exact />
            <Route component={Sections} path="/sections" exact />
            <Route component={General} path="/general" exact />
            <Route component={Settings} path="/settings" exact />
            <Route component={PagSeguroConnect} path="/settings/pagseguro-connect/" exact />
            <Route component={Coupons} path="/coupons" exact />
            <Route component={Products} path="/products" exact />
            <Route component={Product} path="/products/new" exact />
            <Route component={Stores} path="/stores" exact />
            <Route component={Store} path="/stores/edit/:id" />
            <Route component={Store} path="/stores/new" exact />
            <Route component={Integration} path="/integration" exact />
            <Route component={Categories} path="/categories" exact />
            <Route component={Franchisees} path="/franchisees" exact />
            <Route component={Product} path="/products/edit/:id" exact />
            <Route component={FranchiseesOrders} path="/franchisees/orders/:tenantId" exact />
            <Route component={Catalogues} path="/catalogues" exact />
            <Route component={Brands} path="/brands" exact />
            <Route component={HomeIcons} path="/home-icons" exact />
            <Route component={StyleEditor} path="/style-editor" exact />
          </Dashboard>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
