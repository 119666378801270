import React, { memo, useCallback, useState } from 'react';

import { Button, Spinner, Table } from 'reactstrap';

import { FaFileDownload } from 'react-icons/fa';

import DeleteButton from '../DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function ReportsTable({ setReports, reports, loading = false, doUpdate }) {
  const [deleting, setDeleting] = useState([]);

  const list = useCallback(
    () =>
      reports.map((report, index) => (
        <tr key={index}>
          <td>{report.date}</td>
          <td>{report.basename}</td>
          <td>
            <div className="d-flex align-store justify-content-end">
              <a href={`${report.url}`} target="_blank">
                <Button className="mr-1" size="sm" outline color="info">
                  <FaFileDownload />
                </Button>
              </a>
            </div>
          </td>
        </tr>
      )),
    [reports, deleting],
  );

  const doDelete = async (id) => {
    try {
      setDeleting(deleting.concat([id]));

      const url = `admin/tenants/stores/${id}`;

      await api.delete(url);

      doUpdate();
    } catch (e) {
      notification.$e(e);
    } finally {
      setDeleting(deleting.filter((deletingId) => deletingId != id));
    }
  };

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-store w-100" colSpan={3}>
            <Spinner size="sm" color="primary" className="d-block m-auto" />
          </th>
        </tr>
      );
    }

    if (reports.length) {
      return list();
    }

    return (
      <tr>
        <td className="text-center" colSpan={3}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table className="table-fixed" hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('DATE')}</th>
            <th>{util.t('FILE')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(ReportsTable);
