import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';

import CategoriesTable from '~/components/CategoriesTable';
import Form from './Form';

import api from '~/services/api';

import util from '~/assets/util';

function Categories() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const [categoryId, setCategoryId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/menu-categories`);

      let { categories } = res.data;

      setCategories(categories);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newCategory = () => {
    setCategoryId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <header>
                <h1 className="h2">{util.t('CATEGORIES')}</h1>
                <p>{util.t('CATEGORIES_DESCRIPTION')}</p>
              </header>
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <div className="text-right">
              <Button disabled={categories?.length >= 7} onClick={newCategory} className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <CategoriesTable
              loading={loading}
              categories={categories}
              doUpdate={doSearch}
              onOpen={(id) => {
                setCategoryId(id);
              }}
            />
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={categoryId != null}
          toggle={() => {
            setCategoryId(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('CATEGORIES')}</CardHeader>
            <CardBody>
              <Form
                id={categoryId}
                onClose={() => {
                  setCategoryId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
                defaultPosition={categories.length + 1}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Categories;
