import { combineReducers } from 'redux';

import user from './user';
import tenant from './tenant';

const reducers = combineReducers({
  user,
  tenant,
});

export default reducers;
