import React, { memo, useEffect, useState } from 'react';

import { InputGroup, Input, InputGroupAddon } from 'reactstrap';

import util from '~/assets/util';

const PixExpirationField = ({ initialValue, setValue, ...props }) => {
  const [amount, setAmount] = useState(0);
  const [unit, setUnit] = useState('minutes');

  const maxAmount = unit == 'days' ? 30 : unit == 'hours' ? 24 : 60;

  const handleAmountChange = async (e) => {
    setAmount(parseInt(e.target.value) || 0);
  };

  const handleUnitChange = async (e) => {
    setUnit(e.target.value);
  };

  useEffect(() => {
    if (!amount) return;

    let unitInSeconds = 60;
    if (unit == 'hours') {
      unitInSeconds *= 60;
    } else if (unit == 'days') {
      unitInSeconds *= 60 * 24;
    }

    setValue(amount * unitInSeconds);
  }, [amount, unit]);

  useEffect(() => {
    const ONE_HOUR = 60 * 60;
    const ONE_DAY = 60 * 60 * 24;

    if (initialValue < ONE_HOUR) {
      setUnit('minutes');
      setAmount(initialValue / 60);
      return;
    }

    if (initialValue < ONE_DAY) {
      setUnit('hours');
      setAmount(initialValue / ONE_HOUR);
      return;
    }

    setUnit('days');
    setAmount(initialValue / ONE_DAY);
  }, []);

  return (
    <InputGroup {...props}>
      <Input type="number" value={amount || ''} onChange={handleAmountChange} min={1} step={1} max={maxAmount} required />
      <InputGroupAddon addonType="append">
        <Input type="select" value={unit} onChange={handleUnitChange}>
          <option value="minutes">{util.t('MINUTES')}</option>
          <option value="hours">{util.t('HOURS')}</option>
          <option value="days">{util.t('DAYS')}</option>
        </Input>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default memo(PixExpirationField);
