import I18n from '~/assets/i18n';

import { Chars } from './constants';

const util = {
  t(str) {
    return I18n.t(str);
  },

  format(number, decimals = 2, decPoint = ',', thousandsSep = '.') {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number;
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    var sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    var dec = typeof decPoint === 'undefined' ? '.' : decPoint;
    var toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k).toFixed(prec);
    };

    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
  },

  getCurrentDate() {
    var today = new Date();
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0');
    var year = today.getFullYear();

    return year + '-' + month + '-' + day;
  },

  currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value / 100)
      .replace(/^(\D+)/, 'R$ ');

    return `${amount}`;
  },

  randomString(length, chars = Chars.ALPHANUMERIC) {
    let result = '';

    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }

    return result;
  },

  getDateFromTimestamp(value) {
    return value.substr(0, 10).split('/').reverse().join('-');
  },

  escapeSpecialCharacters(string) {
    return encodeURIComponent(encodeURIComponent(string));
  },
};

export default util;
