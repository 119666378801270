import React from 'react';

import util from '~/assets/util';

import Form from './Form';

function Integration() {
  return (
    <main className="card my-3">
      <div className="card-body">
        <header>
          <div className="container-fluid p-3">
            <h1 className="h2 m-0">{util.t('API_INTEGRATION')}</h1>
          </div>
        </header>

        <section>
          <Form />
        </section>
      </div>
    </main>
  );
}

export default Integration;
