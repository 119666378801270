import React, { memo } from 'react';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';

import util from '~/assets/util';

function Filters({ filters, setFilters, onSave, onClear }) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-md-2">
          <Label htmlFor="active">{util.t('STATUS')}</Label>
          <select className="form-control" value={filters.active} id="active" name="active" onChange={handleChange}>
            <option value="">{util.t('ALL')}</option>
            <option value="1">{util.t('ACTIVE')}</option>
            <option value="0">{util.t('INACTIVE')}</option>
          </select>
        </FormGroup>
        <FormGroup className="col-md-2">
          <Label htmlFor="filter_title">{util.t('TITLE')}</Label>
          <Input id="filter_title" name="title" type="text" onChange={handleChange} value={filters.title} />
        </FormGroup>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={() => onClear()} type="button" color="secondary">
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
