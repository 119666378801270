import React from 'react';

import { useParams } from 'react-router-dom';

import Form from '~/components/Products/Form';

import util from '~/assets/util';

function Products() {
  let { id } = useParams();

  return (
    <main className="card my-3">
      <section className="card-body">
        <div className="container-fluid p-3">
          <header className="mb-4">
            <h1 className="h2">{util.t(id ? 'EDIT_PRODUCT' : 'CREATE_PRODUCT')}</h1>
          </header>

          <Form id={id} />
        </div>
      </section>
    </main>
  );
}

export default Products;
