import React, { memo, useEffect, useRef, useState } from 'react';

import { Card, CardBody, CardHeader, Modal as BModal, Spinner, Row, Col, UncontrolledTooltip } from 'reactstrap';

import { FaArrowCircleDown } from 'react-icons/fa';

import Button from '~/components/Button';

import Table from './Table';
import TotalSelectedItems from './TotalSelectedItems';

import util from '~/assets/util';

import styles from './index.module.scss';

function Modal({ isOpen, onClose, loadMore, page, searching, searchResults, onConfirmItems, maxProducts, canLoadMore, disabledBarcodes }) {
  const [selectedIds, setSelectedIds] = useState([]);

  const firstScrollHeight = useRef(0);
  const tableRef = useRef(null);

  const maxItemsExceeded = selectedIds.length > maxProducts;

  useEffect(() => {
    if (!tableRef.current || !searchResults.length) return;

    if (page === 1) {
      firstScrollHeight.current = tableRef.current.scrollHeight;
      return;
    }

    window.setTimeout(() => {
      tableRef.current.scrollTop = firstScrollHeight.current * (page - 1);
    }, 10);
  }, [searchResults]);

  const toggleItemSelection = (id) => {
    try {
      const aux = [...selectedIds];
      if (selectedIds.includes(id)) {
        aux.splice(selectedIds.indexOf(id), 1);
      } else {
        aux.push(id);
      }

      setSelectedIds(aux);
    } catch (e) {
      console.error('erro: ', e);
    }
  };

  const ValidationTooltip = () => {
    if (maxItemsExceeded) {
      return <UncontrolledTooltip target="btn-confirm-products-selection">{util.t('CATALOGUE_MAX_ITEMS_EXCEEDED')}</UncontrolledTooltip>;
    }

    if (selectedIds.length === 0) {
      return (
        <UncontrolledTooltip placement="left" target="btn-confirm-products-selection">
          {util.t('SELECT_AT_LEAST_ONE_PRODUCT')}
        </UncontrolledTooltip>
      );
    }

    return null;
  };

  return (
    <BModal
      autoFocus={false}
      isOpen={isOpen}
      toggle={() => {
        onClose();
      }}
      size="md">
      <Card>
        <CardHeader>{util.t('SELECT_THE_BOOKS')}</CardHeader>
        <CardBody>
          {searching && <Spinner className={styles.loadingSpinner} />}
          <Row>
            <Col>
              <div className={`${styles.tableContainer} ${searching ? styles.loading : ''}`}>
                {searchResults.length != 0 ? (
                  <Table
                    setRef={(element) => (tableRef.current = element)}
                    style={{ visibility: searching ? 'hidden' : 'visible' }}
                    products={searchResults}
                    selectedIds={selectedIds}
                    toggleItemSelection={toggleItemSelection}
                    disabledBarcodes={disabledBarcodes}
                  />
                ) : (
                  !searching && <span className="position-absolute">{util.t('NO_RECORDS_FOUND')}</span>
                )}
              </div>
            </Col>
          </Row>

          <div className={`${searching ? 'invisible' : ''}`}>
            <Row>
              <Col className="text-center">
                {canLoadMore && (
                  <span className="text-primary cursor-pointer" onClick={loadMore}>
                    {util.t('LOAD_MORE')} <FaArrowCircleDown />
                  </span>
                )}
              </Col>
            </Row>

            <Row className="justify-content-center mt-5">
              <Col className="d-flex flex-column justify-content-center">
                <span className="font-weight-bold">
                  <TotalSelectedItems total={selectedIds.length} />
                </span>
              </Col>

              <Col className="d-flex flex-column justify-content-center">
                <footer className="d-flex justify-content-end">
                  <Button className="mr-1" color="danger" onClick={onClose}>
                    {util.t('CANCEL')}
                  </Button>

                  <Button
                    id="btn-confirm-products-selection"
                    color="primary"
                    disabled={selectedIds.length === 0 || maxItemsExceeded}
                    onClick={() => {
                      onConfirmItems(selectedIds);
                      onClose();
                    }}>
                    {util.t('CONFIRM')}
                  </Button>

                  <ValidationTooltip />
                </footer>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
