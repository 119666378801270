import React, { memo } from 'react';

import { Button, InputGroup, InputGroupAddon } from 'reactstrap';

import { FaTimes } from 'react-icons/fa';

import AttributeInput from '../AttributeInput';

function AttributeRow({ attribute, onRemove, onChange }) {
  return (
    <tr>
      <td>{attribute.name}</td>
      <td>
        <InputGroup>
          <AttributeInput attribute={attribute} onChange={onChange} />

          <InputGroupAddon addonType="append">
            <Button color="danger" type="button" onClick={onRemove}>
              <FaTimes />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </td>
    </tr>
  );
}

export default memo(AttributeRow);
