import { Types } from '~/store/constants';

const INITIAL_STATE = {
  data: null,
  token: null,
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.USER_SET:
      return { ...state, data: action.data };
    case Types.USER_TOKEN_SET:
      return { ...state, token: action.token };
    case Types.USER_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
