import React, { memo } from 'react';

import { format, parseISO } from 'date-fns';

import { Table } from 'reactstrap';

import util from '~/assets/util';

function UserResume({ user }) {
  return (
    <Table bordered>
      <tbody>
        <tr>
          <th>{util.t('NAME')}</th>
          <td>{user.name}</td>
        </tr>
        <tr>
          <th>{util.t('CPF_CNPJ')}</th>
          <td>{user.cpf || user.cnpj}</td>
        </tr>
        <tr>
          <th>{util.t('EMAIL')}</th>
          <td>{user.email}</td>
        </tr>
        {user.phone && (
          <tr>
            <th>{util.t('LANDLINE')}</th>
            <td>{user.phone}</td>
          </tr>
        )}
        {user.cell_phone && (
          <tr>
            <th>{util.t('CELL_PHONE')}</th>
            <td>{user.cell_phone}</td>
          </tr>
        )}
        <tr>
          <th>{util.t('REGISTER_AT')}</th>
          <td>{format(parseISO(user.created_at), 'dd/MM/yyyy HH:mm')}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default memo(UserResume);
