import React, { memo, useCallback, useState } from 'react';

import { Button, Spinner, Table } from 'reactstrap';

import { FaSearchPlus } from 'react-icons/fa';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

function BrandsTable({ brands, loading = false, doUpdate, onOpen}) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = async (id) => {
    try {
      setDeleting(deleting.concat([id]));

      const url = `admin/slide-brands/${id}`;

      await api.delete(url);

      doUpdate();
    } catch (e) {
      notification.$e(e);
    } finally {
      setDeleting(deleting.filter((deletingId) => deletingId != id));
    }
  };

  const list = useCallback(
    brands?.map((brand) => (
      <tr key={brand.brand_id}>
        <td>{brand.position}</td>
        <td>{brand.name}</td>
        <td>
          <div className="d-flex align-center justify-content-end">
            <Button className="mr-1" onClick={() => onOpen(brand.brand_id)} size="sm" outline color="info">
              <FaSearchPlus />
            </Button>

            <DeleteButton
              onDelete={() => doDelete(brand.brand_id)}
              size="sm"
              loading={deleting.indexOf(brand.brand_id) > -1}
            />
          </div>
        </td>
      </tr>
    )),
    [brands, deleting],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (brands?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('POSITION')}</th>
            <th>{util.t('NAME')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(BrandsTable);
