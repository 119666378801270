import React, { memo, useRef } from 'react';

import ReactTooltip from 'react-tooltip';
import * as XLSX from 'xlsx';

import { RiFileExcel2Fill } from 'react-icons/ri';

import Button from '../Button';

import styles from './index.module.scss';

function ImportProductsButton({ alt = '', title = '', id, name, onChange, disabled = false, ...props }) {
  const inputRef = useRef(null);

  const openSelectFile = () => {
    return !disabled && inputRef.current.click();
  };

  const handleInputChange = (ev) => {
    ev.preventDefault();

    return changeFile(ev.target.files);
  };

  const changeFile = (files) => {
    if (!files.length) {
      return false;
    }

    const file = files[0];

    const reader = new FileReader();

    const extension = file.name.split('.').pop();

    if (extension == 'csv' || extension == 'xlsx' || extension == 'xls') {
      reader.addEventListener('load', (event) => {
        const base64 = event.target.result;

        const wb = XLSX.read(base64, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        if (base64) {
          onChange(data);

          inputRef.current.value = '';
        }
      });

      reader.readAsBinaryString(file);
    }
  };

  const renderBox = () => {
    return (
      <>
        <Button type="button" className={`${styles.btn} btn btn-primary`} disabled={disabled}>
          <RiFileExcel2Fill size="18" />
          {title}
        </Button>
      </>
    );
  };

  return (
    <>
      <ReactTooltip />
      <section
        {...props}
        data-effect="solid"
        data-background-color="#000"
        data-tip={'"Primeira linha da planilha deve ser esta: BAR_CODE"'}
        className={`${styles.box} ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed filter-grayscale'}`}
        onClick={openSelectFile}>
        {renderBox()}
      </section>
      <input
        accept=".xlsx, .xls, .csv"
        ref={inputRef}
        onChange={handleInputChange}
        className={styles.hiddenFile}
        type="file"
        {...{ id, name }}
      />
    </>
  );
}

export default memo(ImportProductsButton);
