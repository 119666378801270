import React, { useState, useEffect } from 'react';

import ReportsTable from '~/components/ReportsTable';

import api from '~/services/api';

import util from '~/assets/util';

let reportsBaseState = [];

function Reports() {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(reportsBaseState);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/orders/list/sales-reports`);

      let { reports } = res.data;

      reportsBaseState = [...reports];

      setReports(reports);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('GENERATED_REPORTS')}</h1>
              <h6 className="m-0 pb-5">O relatório leva alguns minutos para ser gerado.</h6>
            </header>

            <ReportsTable setReports={setReports} loading={loading} reports={reports} doUpdate={doSearch} />
          </div>
        </section>
      </main>
    </>
  );
}

export default Reports;
