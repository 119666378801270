export const Columns = [
  {
    id: 'code',
    name: 'CODE',
    required: true,
  },
  {
    id: 'name',
    name: 'NAME',
  },
  {
    id: 'weight',
    name: 'WEIGHT_KG',
  },
  {
    id: 'price',
    name: 'PRICE',
  },
  {
    id: 'stock',
    name: 'STOCK',
  },
  {
    id: 'category',
    name: 'CATEGORY',
  },
  {
    id: 'brand',
    name: 'BRAND',
  },
  {
    id: 'cover',
    name: 'COVER_URL',
  },
  {
    id: 'description',
    name: 'DESCRIPTION',
  },
  {
    id: 'width',
    name: 'WIDTH_CM',
  },
  {
    id: 'height',
    name: 'HEIGHT_CM',
  },
  {
    id: 'length',
    name: 'ATTRIBUTE_LENGTH',
  },
  {
    id: 'keywords',
    name: 'KEYWORDS',
  },
  {
    id: 'author',
    name: 'AUTHOR',
  },
  {
    id: 'pages',
    name: 'ATTRIBUTE_PAGES',
  },
  {
    id: 'format',
    name: 'ATTRIBUTE_FORMAT',
  },
];
