import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';

import { Link } from 'react-router-dom';

import Form from './Form';
import Rules from './Rules';
import Filters from './Filters';
import ImportRulesButton from './ImportRulesButton';
import ImportProductsButton from './ImportProductsButton';
import ImportSchoolButton from './ImportSchoolButton';

import ProductsTable from '~/components/ProductsTable';
import Pagination from '~/components/Pagination';

import api from '~/services/api';

import util from '~/assets/util';

const DEFAULT_FILTERS = {
  active: '',
  name: '',
  isbn: '',
  selectedOptionAuthor: [],
  selectedOptionBrand: [],
  store_id: '',
};

function Products() {
  const [ruleId, setRuleId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [productId, setProductId] = useState(null);
  const tenant = useSelector((state: any) => state.tenant);

  const loadRequest = useRef(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    // @ts-ignore
    const cancelToken = api.CancelToken;

    const source = cancelToken.source();

    try {
      setLoading(true);

      if (loadRequest.current) {
        loadRequest.current.cancel();
      } else {
        loadRequest.current = source;
      }

      const res = await api.get(`admin/products/all?page=${page}`, {
        params: filters,
        cancelToken: source.token,
      });

      loadRequest.current = null;

      let { products } = res.data;

      products.links?.shift();
      products.links?.splice(products.links.length - 1, 1);

      setProducts(products);
    } catch (e) {
      console.error(e);
    } finally {
      !loadRequest.current && setLoading(false);
    }
  };

  const newRule = () => {
    setRuleId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={doSearch}
                onClear={() => setFilters({ ...DEFAULT_FILTERS, clearSelect: true })}
              />
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2">{util.t('PRODUCTS')}</h1>
            </header>

            <div className="text-right">
              {tenant.school_booklists > 0 && <ImportSchoolButton className="my-3 mr-1" />}
              
              <ImportProductsButton className="my-3 mr-1" />

              <ImportRulesButton className="my-3 mr-1" />

              <Button onClick={newRule} className="my-3 mr-1" color="primary" outline>
                {util.t('CREATE_DISCOUNT')}
              </Button>

              <Link to="/products/new">
                <Button className="my-3" color="primary" outline>
                  {util.t('CREATE_PRODUCT')}
                </Button>
              </Link>
            </div>

            <ProductsTable
              onOpen={(id) => {
                setProductId(id);
              }}
              loading={loading}
              products={products}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {products && <Pagination onChange={(page) => doSearch(page)} {...products} />}
            </div>
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={ruleId != null}
          toggle={() => {
            setRuleId(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('CREATE_DISCOUNT')}</CardHeader>
            <CardBody>
              <Rules
                id={ruleId}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>

        <Modal
          autoFocus={false}
          isOpen={productId != null}
          toggle={() => {
            setProductId(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('PRODUCT')}</CardHeader>
            <CardBody>
              <Form
                id={productId}
                onClose={() => {
                  setProductId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Products;
