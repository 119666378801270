import React, { memo, useState } from 'react';

import Creatable from 'react-select/creatable';

import util from '~/assets/util';

function KeywordsInput({ keywordsString, onChangeString, ...props }) {
  const [keywords, setKeywords] = useState(keywordsString.split(' '));

  return (
    <Creatable
      {...props}
      isMulti
      isClearable
      getOptionLabel={(option) => option.label || option}
      getOptionValue={(option) => option.label || option}
      placeholder="..."
      formatCreateLabel={(name) => `${util.t('LABEL_INSERT')} "${name}"`}
      noOptionsMessage={() => util.t('TYPE_A_WORD')}
      onChange={(options) => {
        const words = options.map((option) => option.label || option);

        setKeywords(words);

        onChangeString(words.join(' '));
      }}
      value={keywords}
    />
  );
}

export default memo(KeywordsInput);
