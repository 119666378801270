export const Types = {
  TENANT_SET: 'TENANT_SET',

  USER_SET: 'USER_SET',
  USER_RESET: 'USER_RESET',
  USER_TOKEN_SET: 'USER_TOKEN_SET',
};

export const StoreSlug = {
  CATAVENTO: 'catavento',
};
