import React, { memo, useEffect, useRef, useState } from 'react';

import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

import { FaPlus } from 'react-icons/fa';

import AsyncSelect from 'react-select/async';

import debounce from 'lodash.debounce';

import CategoryModal from '../CategoryModal';

import api from '~/services/api';

import util from '~/assets/util';

function SearchCategoryInput({ onChange, setRef = null, ...props }) {
  const [showModal, setShowModal] = useState(false);

  const newCategoryButtonRef = useRef(null);

  const searchCategories = async (inputValue) => {
    try {
      const res = await api.get(`admin/menu-categories/search?q=${inputValue}`);

      const { categories } = res?.data;

      if (!categories) {
        return [];
      }

      return categories;
    } catch (e) {
      console.error(e);
    }
  };

  const getOptionLabel = (category) => {
    let label = category.name;

    if (category.parent) {
      label += ` (${category.parent.name})`;
    }

    return label;
  };

  const loadCategories = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchCategories(inputValue));
    });

  const debouncedLoadCategories = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadCategories(inputValue).then((options) => callback(options));
  }, 1000);

  const handleNewCategory = (category) => {
    onChange(props.isMulti ? props.value.concat(category) : category);

    setShowModal(false);
  };

  return (
    <>
      <InputGroup>
        <AsyncSelect
          ref={setRef}
          className="react-select-full-width"
          placeholder={util.t('SEARCH_THREE_DOTS')}
          getOptionValue={(option) => option.category_id}
          getOptionLabel={getOptionLabel}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          loadOptions={debouncedLoadCategories}
          {...props}
          isClearable
          noOptionsMessage={() => util.t('NO_OPTION')}
          onChange={onChange}
          required
        />

        <InputGroupAddon addonType="append">
          <Button
            className="new-category-button"
            alt={util.t('NEW_CATEGORY')}
            type="button"
            onClick={() => setShowModal(true)}
            size="sm"
            color="primary"
            innerRef={(ref) => {
              newCategoryButtonRef.current = ref;
            }}>
            <FaPlus />
          </Button>

          <UncontrolledTooltip target={newCategoryButtonRef}>{util.t('NEW_CATEGORY')}</UncontrolledTooltip>
        </InputGroupAddon>
      </InputGroup>

      <CategoryModal id={0} isOpen={showModal} onSave={handleNewCategory} onToggle={() => setShowModal(false)} />
    </>
  );
}

export default memo(SearchCategoryInput);
