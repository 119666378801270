import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Alert, Button } from 'reactstrap';

import { FaInfo, FaPencilAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';

import StoresTable from '~/components/StoresTable';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

let storesBaseState = [];

function Stores() {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState(storesBaseState);

  const [editPriority, setEditPriority] = useState(false);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/tenants/stores?page=${page}`);

      let { stores } = res.data;

      storesBaseState = [...stores];

      setStores(stores);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const doSavePriority = async () => {
    if (loading) {
      return false;
    }

    setLoading(true);

    try {
      const url = 'admin/tenants/stores/priority/update';

      const form = stores.map((store, index) => ({
        store_id: store.store_id,
        priority: index + 1,
      }));

      await api.post(url, { stores: form });

      notification.$s(util.t('PRIORITY_ORDER_SAVED'));

      doSearch();

      setEditPriority(false);
    } catch (e) {
      console.error(e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  const doCancelPriorityEdit = () => {
    setEditPriority(false);

    setStores([...storesBaseState]);
  };

  const confirmPriority = () => {
    Swal.fire({
      html: util.t('CONFIRM_PRIORITY_FACTOR'),
      showCancelButton: true,
      cancelButtonText: util.t('CANCEL'),
      confirmButtonText: util.t('CONFIRM'),
    }).then((result) => {
      result.isConfirmed && doSavePriority();
    });
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('DISTRIBUTION_CENTERS')}</h1>
            </header>

            <div className="text-right">
              <Link to="stores/new">
                <Button className="my-3 mr-1" color="primary" outline>
                  {util.t('ADD')}
                </Button>
              </Link>
            </div>

            {editPriority && (
              <Alert color="info">
                <FaInfo />
                {util.t('PRIORITY_FACTOR_TIP')}
              </Alert>
            )}

            <StoresTable editPriority={editPriority} setStores={setStores} loading={loading} stores={stores} doUpdate={doSearch} />

            <div className="d-flex justify-content-end mt-4">
              {editPriority ? (
                <>
                  <Button onClick={doCancelPriorityEdit} className="my-3 mr-1" color="danger">
                    {util.t('CANCEL')}
                  </Button>

                  <Button onClick={confirmPriority} className="my-3" color="primary">
                    {util.t('SAVE')}
                  </Button>
                </>
              ) : (
                <Button onClick={() => setEditPriority(true)} className="my-3" color="primary" disabled={stores.length < 2}>
                  <FaPencilAlt className="mr-2" />
                  {util.t('PRIORITY_FACTOR')}
                </Button>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Stores;
