import React, { memo, useEffect, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';

import Select from 'react-select';

import Button from '~/components/Button';
import RulesTable from '~/components/RulesTable';
import SearchBrandInput from '~/components/Brands/SearchBrandInput';
import SearchAuthorInput from '~/components/Authors/SearchAuthorInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function Rules({ id, onSave }) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [dataRules, setDataRules] = useState([]);

  const [rules, setRules] = useState({
    discount_type: '',
    author_id: '',
    brand_id: [],
    discount_percent: '',
    expires_at: '',
  });

  const handleChangeSelect = (ev) => {
    if (!ev) {
      return;
    }

    const name = ev.name;
    const value = ev.value;

    if (value == 'author') {
      rules.brand_id = [];
    } else if (value == 'brand') {
      rules.author_id = '';
    }

    setRules({ ...rules, [name]: ev.value });
  };

  const handleChangeBrands = (brands) => {
    brands && setRules({ ...rules, brand_id: brands });
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setRules({ ...rules, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...rules,
        brand_id: rules.brand_id.map((option) => option.value),
      };

      const url = 'admin/products/rule/add';

      const res = await api.post(url, form);

      const data = res?.data;

      if (data.status == true) {
        onSave();
        doRefreshRules();

        notification.$s(util.t('DISCOUNT_CREATED'));
      } else {
        notification.$e(util.t('DISCOUNT_CREATED_ERROR'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  async function doRefreshRules() {
    setLoading(true);

    try {
      const res = await api.get('admin/products/rule/get');

      const { rules } = res?.data;

      if (rules) {
        setDataRules(rules);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function loadRules() {
      setLoading(true);

      try {
        const res = await api.get('admin/products/rule/get');

        const { rules } = res?.data;

        if (rules) {
          setDataRules(rules);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    loadRules();
  }, []);

  const discountTypes = [
    { name: 'discount_type', value: 'brand', label: util.t('BRAND') },
    { name: 'discount_type', value: 'author', label: util.t('AUTHOR') },
  ];

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset>
              <Row form>
                <Col lg="3">
                  <FormGroup>
                    <Label htmlFor="discount_type">{util.t('DISCOUNT_TYPE')}</Label>
                    <Select
                      name="discount_type"
                      placeholder={util.t('SELECT')}
                      defaultValue=""
                      isSearchable={false}
                      onChange={handleChangeSelect}
                      options={discountTypes}
                    />
                  </FormGroup>
                </Col>
                {rules.discount_type == 'author' || rules.discount_type == 'brand' ? (
                  <>
                    <Col lg="3">
                      {rules.discount_type == 'author' && (
                        <FormGroup>
                          <Label htmlFor="author">{util.t('AUTHORS')}</Label>
                          <SearchAuthorInput onChange={handleChangeSelect} />
                        </FormGroup>
                      )}

                      {rules.discount_type == 'brand' && (
                        <FormGroup>
                          <Label htmlFor="brand">{util.t('BRANDS')}</Label>

                          <SearchBrandInput isMulti onChange={handleChangeBrands} canCreate={false} />
                        </FormGroup>
                      )}
                    </Col>
                    <Col lg="2">
                      <FormGroup>
                        <Label htmlFor="discount_percent">{util.t('DISCOUNT_PERCENTAGE')}</Label>
                        <Input
                          className="form-control"
                          onChange={handleChange}
                          type="number"
                          step="0.01"
                          min={0.1}
                          max={100}
                          name="discount_percent"
                          id="discount_percent"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      <FormGroup>
                        <Label htmlFor="expires_at">{util.t('EXPIRATION_DATE')}</Label>
                        <Input
                          className="form-control"
                          onChange={handleChange}
                          type="date"
                          min={util.getCurrentDate()}
                          name="expires_at"
                          id="expires_at"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      <FormGroup>
                        <Label>{util.t('ACTION')}</Label>
                        <footer className="d-flex justify-content-start">
                          <Button color="primary" type="submit" loading={saving}>
                            {util.t('SAVE')}
                          </Button>
                        </footer>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
            </fieldset>
            <hr />
          </>
        )}
      </form>
      <Row>
        <Col>
          <RulesTable
            loading={loading}
            rules={dataRules}
            onOpen={(rules) => {
              setDataRules(rules);
            }}
            doUpdate={() => {
              doRefreshRules();
              onSave();
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default memo(Rules);
