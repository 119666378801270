import axios from "axios";

const service = {
  async search(zipcode) {
    let result = {};

    try {
      const res = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

      const data = res.data;

      if (data.bairro) {
        result.neighborhood = data.bairro;
      }

      if (data.uf) {
        result.state = data.uf;
      }

      if (data.localidade) {
        result.city = data.localidade;
      }

      if (data.logradouro) {
        result.street = data.logradouro;
      }

      return result;
    } catch (e) {
      console.error(e);
    }
  },
};

export default service;
