import React, { memo, useState } from 'react';

import { Button } from 'reactstrap';

import Modal from './Modal';

import util from '~/assets/util';

function CouponsGeneratorButton({ doUpdate }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowModal(true)} className="my-3" color="primary" outline>
        {util.t('COUPONS_GENERATOR')}
      </Button>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)} onSave={doUpdate} />
    </>
  );
}

export default memo(CouponsGeneratorButton);
