import React, { memo, useEffect, useState } from 'react';

import { FaCheck, FaPlug } from 'react-icons/fa';
import { Badge, Button, Spinner } from 'reactstrap';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function PagSeguroConnectButton(props) {
  const [connected, setConnected] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchStatus = async () => {
    try {
      const url = 'admin/pagseguro-connect';
      const res = await api.get(url);

      let { connected, link } = res.data;

      setConnected(connected);
      setLink(link);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (loading) {
    return (
      <Badge color="info" {...props}>
        <Spinner size="sm" /> {util.t('VERIFYING_PAGSEGURO_CONNECTION')}
      </Badge>
    );
  }

  if (connected) {
    return (
      <Badge color="success" {...props}>
        <FaCheck /> {util.t('CONNECTED_TO_PAGSEGURO')}
      </Badge>
    );
  }

  return (
    <div className="alert alert-warning" role="alert">
      {util.t('LINK_YOUR_PAGSEGURO_ACCOUNT_TO_UPGRADE')}
      <br />
      <Button tag="a" color="warning" href={link} target="_blank" disabled={props.disabled} {...props}>
        <FaPlug /> {util.t('CONNECT')}
      </Button>
    </div>
  );
}

export default memo(PagSeguroConnectButton);
