import React, { FormEvent, useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { sass } from '@codemirror/lang-sass';
import { Col, Form, Row, Spinner } from 'reactstrap';

import Button from '~/components/Button';

import util from '~/assets/util';
import api from '~/services/api';
import notification from '~/services/notification';

function StyleEditor() {
  const [value, setValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadStyles();
  }, []);

  const onChange = React.useCallback((val, viewUpdate) => {
    setValue(val);
  }, []);

  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (!value) {
      notification.$e(util.t('STYLE_EMPTY'));
      return false;
    }
    setSaving(true);

    try {
      await api.post(`admin/theming`, { scss: value });
      notification.$s(util.t('STYLE_SAVED'));
    } catch (e) {
      console.error('StyleEditor.onSubmit', e);
      notification.$e(util.t('STYLE_EDITOR_ERROR_SYNTAX'));
    } finally {
      setSaving(false);
    }
  };

  async function loadStyles() {
    try {
      setLoading(true);
      const { data } = await api.get(`admin/theming`);

      if (data?.scss) setValue(data.scss);
    } catch (e) {
      console.error('StyleEditor.loadStyles', e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-0">
            <header>
              <h1 className="h2 m-0">{util.t('STYLE_EDITOR')}</h1>
            </header>

            {loading && (
              <div className="d-flex justify-content-center">
                <Spinner size="sm" color="primary" />
              </div>
            )}
            {!loading && (
              <Form onSubmit={onSubmit} className="mt-2">
                <Row>
                  <Col>
                    <CodeMirror
                      value={value}
                      height="calc(100vh - 260px)"
                      extensions={[sass()]}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4">
                    <Button type="submit" color="primary" loading={saving}>
                      {util.t('SAVE')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </section>
      </main>
    </>
  );
}
export default StyleEditor;
