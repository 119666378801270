import React, { memo, useRef, useState, useEffect } from 'react';

import debounce from 'lodash.debounce';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';
import AsyncSelect from 'react-select/async';

import FormButtons from '~/components/FormButtons';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

function Form({ id, onClose, onSave, defaultPosition = 1 }) {
  const [saving, setSaving] = useState(false);
  const [brand, setBrand] = useState({
    brand_id: null,
    tenant_id: 0,
    name: '',
    position: defaultPosition,
  });

  const [loading, setLoading] = useState(false);

  const selectRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setBrand({ ...brand, [name]: ev.target.value });
  };

  const handleChangeSelect = (ev) => {
    ev && setBrand({ ...brand, brand_id: ev.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    if (!brand.brand_id) {
      return notification.$e(util.t('SELECT_A_BRAND'));
    }

    setSaving(true);

    try {
      const res = id ? await api.put(`admin/slide-brands/${id}`, brand) : await api.post(`admin/slide-brands/create`, brand);

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('BRAND_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  async function loadDataOptions(inputValue) {
    try {
      const res = await api.get(`admin/slide-brands/search?q=${inputValue}`);

      const { brands } = res?.data;

      if (brands) {
        const selectOptions = [];
        brands?.map((item) => {
          const option = new Object({ value: item.brand_id, label: item.name });
          selectOptions.push(option);
        });

        return selectOptions?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
      }
    } catch (e) {
      console.error(e);
    }
  }

  const loadBrands = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions(inputValue));
    });

  const debouncedLoadBrands = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadBrands(inputValue).then((options) => callback(options));
  }, 1000);

  useEffect(() => {
    selectRef.current?.focus();

    async function loadBrand() {
      setLoading(true);

      try {
        const res = await api.get(`admin/slide-brands/${id}`);

        const data = res?.data;

        const brand = data?.brand;

        setBrand(brand);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadBrand();
    }
  }, []);

  if (loading) {
    return (
      <div className="p-5">
        <Spinner size="lg" className="d-block m-auto" color="primary" />
      </div>
    );
  }

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        <>
          <fieldset>
            <Row form>
              <Col md="6" xl="4">
                <Label htmlFor="name">{util.t('BRAND')}</Label>
                <AsyncSelect
                  ref={(ref) => (selectRef.current = ref)}
                  defaultValue={{ label: brand.name, value: brand.brand_id }}
                  required={true}
                  cacheOptions
                  isClearable
                  placeholder={util.t('SEARCH_THREE_DOTS')}
                  loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
                  noOptionsMessage={() => util.t('NO_OPTION')}
                  onChange={handleChangeSelect}
                  loadOptions={debouncedLoadBrands}
                />
              </Col>
            
              <Col md="1">
                <FormGroup>
                  <Label htmlFor="link">{util.t('POSITION')}</Label>
                  <Input
                    type="number"
                    min="1"
                    max="10"
                    name="position"
                    id="position"
                    required={true}
                    value={brand.position}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <FormButtons isEditing={false} isSaving={saving} onCancel={() => onClose()} onEdit={() => {}} />
              </footer>
            </Col>
          </Row>
        </>
      </form>
    </>
  );
}

export default memo(Form);
