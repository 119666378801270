import React, { ReactNode, memo } from 'react';

import util from '~/assets/util';

interface Props {
  title: string;
  Icon: any;
  value?: number;
  children?: ReactNode;
}

function InfoCard(props: Props) {
  return (
    <section className="bg-white p-3 card">
      <h1 className="h5">{util.t(props.title)}</h1>

      <div className="d-flex align-items-center mt-1">
        {props.children || <h2 className="m-0 mr-auto">{props.value}</h2>}
        <span>{props.Icon}</span>
      </div>
    </section>
  );
}

export default memo(InfoCard);
