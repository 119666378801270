import React, { useState } from 'react';

import { Input } from 'reactstrap';

import { FaRedo } from 'react-icons/fa';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

import Button from '~/components/Button';
import BtnCopy from '~/components/BtnCopy';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

const ReactSwal = withReactContent(Swal);

function SecretKeyInput() {
  const [secretKey, setSecretKey] = useState('');
  const [loading, setLoading] = useState(false);

  const openConfirmDialogue = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#df4759',
      html: util.t('NEW_SECRET_KEY_ADVICE'),
      showCancelButton: true,
      confirmButtonText: util.t('CONFIRM'),
      cancelButtonText: util.t('CANCEL'),
      confirmButtonColor: '#df4759',
    }).then((result) => {
      result.isConfirmed && doGenerate();
    });
  };

  const showGeneratedKey = (key) => {
    ReactSwal.fire({
      icon: 'info',
      html: (
        <>
          <p>{util.t('GENERATED_SECRET_KEY_ADVICE')}</p>

          <p className="alert alert-success text-center">{key}</p>

          <BtnCopy text={key} />
        </>
      ),
      confirmButtonText: util.t('CONFIRM'),
      showConfirmButton: false,
    });
  };

  const doGenerate = async () => {
    try {
      setLoading(true);

      const url = 'admin/tenants/integration/secret/refresh';

      const res = await api.post(url);

      const key = res.data.secret_key;

      showGeneratedKey(key);

      setSecretKey(key);
    } catch (e) {
      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {secretKey ? (
        <>
          <Input className="form-control" value={secretKey} type="text" name="secret_key" disabled />
          <BtnCopy className="mt-2" text={secretKey} />
        </>
      ) : (
        <Button loading={loading} color="danger" type="button" className="d-block" onClick={openConfirmDialogue}>
          <FaRedo className="mr-1" />
          {util.t('GENERATE')}
        </Button>
      )}
    </>
  );
}

export default SecretKeyInput;
