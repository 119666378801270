import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { FaFileExcel } from 'react-icons/fa';

import Button from '~/components/Button';
import Table from './Table';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function Franchisees() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [franchisees, setFranchisees] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const doSearch = async () => {
    try {
      setLoading(true);

      const res = await api.get('admin/franchising/list');

      const { franchisees } = res.data;

      setFranchisees(franchisees);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const createReport = async () => {
    try {
      setLoadingReport(true);

      const res = await api.post('admin/franchising/sales-report');

      const { path } = res.data;

      location.href = path;
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('FRANCHISEES')}</h1>
            </header>

            <div className="text-right">
              <Button
                disabled={!franchisees?.length}
                loading={loadingReport}
                onClick={createReport}
                className="mb-3 align-items-center"
                color="primary"
                outline>
                <FaFileExcel className="mr-1" />
                {util.t('SALES_REPORT')}
              </Button>
            </div>

            <Table
              onOpen={(id) => {
                history.push(`franchisees/orders/${id}`);
              }}
              loading={loading}
              franchisees={franchisees}
            />
          </div>
        </section>
      </main>
    </>
  );
}

export default Franchisees;
