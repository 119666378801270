import React, { memo } from 'react';

import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Form from '~/pages/Coupons/Form';

import util from '~/assets/util';

function CouponsGeneratorModal({ toggle, onSave, ...props }) {
  return (
    <Modal autoFocus={false} {...props} size="xl" toggle={toggle}>
      <Card>
        <CardHeader>{util.t('COUPONS_GENERATOR')}</CardHeader>
        <CardBody>
          <Form onSave={onSave} onClose={toggle} withGenerator />
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(CouponsGeneratorModal);
