import React, { memo } from 'react';

import Switch from 'react-switch';

import { Badge } from 'reactstrap';

import util from '~/assets/util';

import './index.scss';

function StatusSwitch(props) {
  const active = props.checked;

  return (
    <div className="status-switch-box" id="status-switch-box">
      <Switch {...props} />
      <Badge href="#" className="ml-1" color={active ? 'success' : 'secondary'}>
        {util.t(active ? 'ACTIVE' : 'INACTIVE')}
      </Badge>
    </div>
  );
}

export default memo(StatusSwitch);
