import React, { memo, useState } from 'react';

import { FaRedo, FaTimes, FaCheck } from 'react-icons/fa';

import Button from '~/components/Button';

import util from '~/assets/util';

import api from '~/services/api';

import Swal from 'sweetalert2';

import notification from '~/services/notification';

import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);

function SendOrderButton({ storeOrder, ...props }) {
  const [loading, setLoading] = useState(false);

  const showDialog = () => {
    if (loading) return false;

    ReactSwal.fire({
      html: util.t('CONFIRM_RESEND_ORDER'),
      showCancelButton: true,
      cancelButtonText: <FaTimes />,
      confirmButtonText: <FaCheck />,
      confirmButtonColor: '#df4759',
    }).then(async (result) => {
      result.isConfirmed && doSendOrder();
    });
  };

  const doSendOrder = async () => {
    setLoading(true);

    try {
      await api.post('admin/winbooks/orders/send', {
        store_order_id: storeOrder.store_order_id,
      });

      notification.$s(util.t('ORDER_WILL_BE_SENT'));
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button {...props} color="warning" spinnerColor="#000" loading={loading} onClick={storeOrder.winbooks_code ? showDialog : doSendOrder}>
      <FaRedo />
    </Button>
  );
}

export default memo(SendOrderButton);
