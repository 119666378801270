import React, { memo } from 'react';

import MaskedInput from 'react-text-mask';

interface InputIeProps {
  name?: string;
  value: any;
  onChange: any;
  id?: string;
  className: string;
  required?: boolean;
  placeholder?: string;
}

const InputIe = ({ value, name, onChange, id, className, required = false, placeholder = '' }: InputIeProps) => {
  return (
    <MaskedInput
      {...{ onChange, value, name, id, className, required, placeholder }}
      type="text"
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/]}
    />
  );
};

export default memo(InputIe);
