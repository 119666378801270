import React, { memo, useCallback, useEffect, useState } from 'react';

import { FaSearchPlus } from 'react-icons/fa';

import { Button, Spinner, Table as BTable } from 'reactstrap';

import util from '~/assets/util';

function Table({ franchisees, loading = false, onOpen }) {
  const list = useCallback(
    franchisees?.map((franchisee) => (
      <tr key={franchisee.tenant_id}>
        <td>{franchisee.name}</td>
        <td>{franchisee.slug}</td>
        <td className="d-flex align-center justify-content-end">
          <Button onClick={() => onOpen(franchisee.tenant_id)} size="sm" className="mr-1" outline color="info">
            <FaSearchPlus />
          </Button>
        </td>
      </tr>
    )),
    [franchisees],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!franchisees?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={4}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return list;
  };

  return (
    <>
      <BTable hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('NAME')}</th>
            <th>{util.t('SLUG')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </BTable>
    </>
  );
}

export default memo(Table);
