import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Redirect } from 'react-router-dom';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

import './index.scss';

function Dashboard({ children }) {
  const user = useSelector((state: any) => state.user);

  const [collapsed, setCollapsed] = useState(true);

  if (!user.data) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <main id="wrapper" className={collapsed ? 'collapsed' : ''}>
        <Navbar {...{ collapsed, setCollapsed }} />
        <Sidebar {...{ collapsed, setCollapsed }} />

        <div className="content-page">
          <div className="content">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
